import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)
// const
// import HelloWorld from '@/components/HelloWorld.vue'
export default new Router({
    mode: 'hash',
    routes: [
        {
            path: '/',
            name: 'CXC',
            component: () => import('@/views/index/index'),
            meta: {
                title: 'coinbese',
                ENtitle: 'coinbese'

            }
        },
        {
            path: '/success',
            name: 'Success',
            component: () => import('@/views/index/success'),
            meta: {
                title: 'coinbese',
                ENtitle: 'coinbese'

            }
        },
        {
            path: '/verification',
            name: 'verification',
            component: () => import('@/views/index/verification'),
            meta: {
                title: 'coinbese',
                ENtitle: 'coinbese'

            }
        },
        {
            path: '/exchange',
            name: 'exchange',
            component: () => import('@/views/index/exchange'),
            meta: {
                title: 'coinbese',
                ENtitle: 'coinbese'

            }
        },
        {
            path: '/personal',
            name: 'personal',
            component: () => import('@/views/index/personal'),
            meta: {
                title: 'coinbese',
                ENtitle: 'coinbese'

            }
        },
        {
            path: '/serve',
            name: 'serve',
            component: () => import('@/views/index/serve'),
            meta: {
                title: 'coinbese',
                ENtitle: 'coinbese'

            }
        },
        {
            path: '/fundslog',
            name: 'fundslog',
            component: () => import('@/views/index/fundslog'),
            meta: {
                title: 'coinbese',
                ENtitle: 'coinbese'

            }
        },
        {
            path: '/withdrawalslog',
            name: 'withdrawalslog',
            component: () => import('@/views/index/withdrawalslog'),
            meta: {
                title: 'coinbese',
                ENtitle: 'coinbese'

            }
        },
        {
            path: '/lottery',
            name: 'lottery',
            component: () => import('@/views/index/lottery'),
            meta: {
                title: 'coinbese',
                ENtitle: 'coinbese'

            }
        },
        {
            path: '/404',
            name: '404',
            component: () => import('@/views/index/404'),
            meta: {
                title: 'coinbese',
                ENtitle: 'coinbese'

            }
        }

    ],
    base: process.env.BASE_URL,

});
