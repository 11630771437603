import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'vant/lib/index.css';
import Vant from 'vant';
Vue.use(Vant);
import MuseUI from 'muse-ui';
import 'muse-ui/dist/muse-ui.css';
import 'muse-ui-message/dist/muse-ui-message.css';
import Message from 'muse-ui-message';
import VueTippy, { TippyComponent } from "vue-tippy";
import iView from 'iview';
import 'iview/dist/styles/iview.css';    // 使用 CSS
Vue.use(iView);
Vue.use(VueTippy);
// or
import CountryFlag from 'vue-country-flag'
Vue.component('country-flag', CountryFlag)

Vue.use(VueTippy, {
  directive: "tippy", // => v-tippy
  flipDuration: 0,
  popperOptions: {
    modifiers: {
      preventOverflow: {
        enabled: false
      }
    }
  }
});

Vue.component("tippy", TippyComponent);
import "tippy.js/themes/light.css";
import "tippy.js/themes/light-border.css";
import "tippy.js/themes/google.css";
import "tippy.js/themes/translucent.css";
//main.js中全局引入
// import clipboard from 'clipboard';
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);
import Web3 from 'web3'
Vue.prototype.Web3 = Web3
import VueQriously from 'vue-qriously'
import SlideVerify from 'vue-monoplasty-slide-verify';
Vue.use(SlideVerify);
Vue.use(VueQriously)

// 完整加载
import VueLuckyCanvas from '@lucky-canvas/vue'
Vue.use(VueLuckyCanvas)

import VueClipboards from 'vue-clipboard2'
Vue.use(VueClipboards);

Vue.config.productionTip = false
Vue.use(MuseUI);
Vue.use(Message);

const i18n = new VueI18n({
  locale: localStorage.getItem('locale') || 'gb',   //从localStorage里获取用户中英文选择，没有则默认中文
  messages: {
    'cn': require('@/assets/lang/zh.js'),
    'gb': require('@/assets/lang/en.js'),
    'jp': require('@/assets/lang/jp.js'),
    'es': require('@/assets/lang/es.js'),
    'vn': require('@/assets/lang/vn.js'),
  }
});

router.beforeEach((to, from, next) => {
  document.body.scrollTop = 0
  document.documentElement.scrollTop = 0
  window.pageYOffset = 0
  next()

});

new Vue({
  router,
  i18n,
  store,
  render: h => h(App),
}).$mount('#app')
