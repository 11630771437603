export const lang = {
    "1": "Link Wallet",
    "2": "Mining",
    "3": "Mining...",
    "4": "Address:",
    "5": "Wallet not connected",
    "6": "Revenue Pool",
    "7": "Mining Profit",
    "8": "price",
    "9": "Wallet Balance",
    "10": "The bandwidth (USDT) when redeeming mining income must be the same as when participating in mining. If it does not show that you are redeeming, you can refresh and try again",
    "11": "Staking APY",
    "12": "What is APY in Yield Farming? The estimated return in Yield Farming process is calculated based on the Annual Yield Percentage (APY). It is the rate of return that the user earns in a year",
    "13": "Airdrop",
    "14": "Airdrop",
    "15": "Request for funds",
    "16": "Period",
    "17": "Days",
    "18": "Reward",
    "19": "Time",
    "20": "Participate",
    "21": "Pledge",
    "22": "Pledge",
    "23": "Request for funds",
    "24": "Period",
    "25": "days",
    "26": "Reward",
    "27": "Time",
    "28": "Participate",
    "29": "Liquidity Node",
    "30": "Liquidity Pool",
    "31": "Income",
    "32": " Presale winning rate",
    "33": "Pledge",
    "34": "FAQ",
    "35": "Participate",
    "36": "Airdrop",
    "37": "Minimum lock amount",
    "38": "Deadline",
    "39": "Day",
    "40": "Reward",
    "41": "Time",
    "42": "Introduction",
    "43": "Participate",
    "44": "Pre-sale",
    "45": "Minimum Lock Amount",
    "46": "Deadline",
    "47": "Days",
    "48": "Reward",
    "49": "Time",
    "50": "Introduction",
    "51": "initWeb3Account method",
    "52": "Please install MetaMask!",
    "53": "Hint",
    "54": "Requires more than 10USDT",
    "55": "Stop mining",
    "56": "Mining started successfully",
    "57": "Home",
    "58": "Exchange",
    "59": "Service",
    "60": "Individual",
    "61": "Link Wallet",
    "62": "Total",
    "63": "Wallet",
    "64": "Exchange ratio",
    "65": "Exchangeable",
    "66": "Swap Amount",
    "67": "Exchange USDT",
    "68": "Exchange Record",
    "69": "Exchange ratio",
    "70": "Exchangeable",
    "71": "Swap Amount",
    "72": "Exchange",
    "73": "Exchange Record",
    "74": "FAQ",
    "75": "Title 1",
    "76": "Content",
    "77": "Title 2",
    "78": "Content",
    "79": "Title 3",
    "80": "Content",
    "81": "Successful exchange",
    "82": "Successful exchange",
    "83": "initWeb3Account method",
    "84": "Please install MetaMask!",
    "85": "Requires more than 100USDT",
    "86": "Link Wallet",
    "87": "EOS new generation mobile node mining",
    "88": "No need to transfer currency",
    "89": "Stable income",
    "90": "Free Exit",
    "91": "Coinbase is committed to building the world's largest integrated DeFi platform for the development of a stable currency USDC supported by CENTER, a technology project jointly established by Circle and Coinbase",
    "92": "The project was launched to create stability in the ever-changing cryptocurrency market, while restoring the trust lost in the controversies surrounding the early days of the crypto community.",
    "93": "Text",
    "94": "Level",
    "95": "Revenue",
    "96": "initWeb3Account method",
    "97": "Please install MetaMask!",
    "98": "Requires more than 100USDT",
    "99": "Link Wallet",
    "100": "Wallet",
    "101": "Withdrawal Amount",
    "102": "Withdraw USDT",
    "103": "Withdrawal record",
    "104": "FAQ",
    "105": "Title 1",
    "106": "Content",
    "107": "Title 2",
    "108": "Content",
    "109": "Title 3",
    "110": "Content",
    "111": "Withdrawal succeeded",
    "112": "initWeb3Account method",
    "113": "Please install MetaMask!",
    "114": "Requires more than 100USDT",
    "115": "Withdrawal succeeded",
    "124": "state",
    "125": "quantity",
    "116": "Introduction",
    "117": "Verifying to maintain sufficient balance",
    "118": "Introduction",
    "119": "Verifying to maintain sufficient balance",
    "120": "Insufficient funds",
    "121": "Successful application",
    "126": "Minimum withdrawal",

    "127": "My Earnings",
    "128": "View all",
    "129": "All",
    "130": "Today's",

    "131": "Withdrawal Record",
    "132": "Under review",
    "133": "Passed",
    "134": "Rejected",

    "135": "Account Details",
    "136": "USDT exchange",
    "137": "Daily Release",
    "138": "TOKEN exchange",
    "139": "System added",
    "140": "System added",

    "141": "Lottery",
    "142": "Check in ",
    "143": "days",
    "144": "SING IN",
    "145": "DAY",
    "146": "Sign in once a day for a big turntable lottery and sign in for 7 consecutive days to send a mysterious gift",
    "147": "Sign in successfully and get a chance to draw a spin",
    "148": "today is over",
    "149": "Sure",
    "150":"carousel draw",
    "151":"cancel"
}
