export const lang = {
"1":"リンクウォレット",
"2":"掘削",
"3":"掘削中…",
"4":"住所:",
"5":"ウォレットが接続されていない",
"6":"収益プール",
"7":"掘削収益",
"8":"EOS価格",
"9":"ウォレット残高",
"10":"マイニング収入を両替する際の帯域幅（USDT）は,マイニングに参加する際の帯域幅と一致している必要があります。両替していることが表示されていない場合は,リフレッシュして再試行することができます",
"11":"掘削年利率",
"12":"単産農業におけるAPYとは何か。単産農業過程における推定リターンは年間生産量パーセント（APY）に基づいて計算される。これはユーザーが1年以内に得たリターン率である",
"13":"空中投下",
"14":"空中投下",
"15":"資金要求",
"16":"周期",
"17":"日",
"18":"奨励",
"19":"時間",
"20":"参加",
"21":"質押",
"22":"質押",
"23":"資金要求",
"24":"サイクル",
"25":"日",
"26":"奨励",
"27":"時間",
"28":"参加",
"29":"流動資金ノード",
"30":"流動資金プール",
"31":"収益",
"32":"前売り当選率",
"33":"質押",
"34":"FAQ",
"35":"参加",
"36":"空中投下",
"37":"最小ロック金額",
"38":"期限",
"39":"日",
"40":"奨励",
"41":"時間",
"42":"紹介",
"43":"参加",
"44":"前売り",
"45":"最小ロック金額",
"46":"期限",
"47":"日",
"48":"奨励",
"49":"時間",
"50":"紹介",
"51":"initWeb 3 Accountメソッド",
"52":"MetaMaskをインストールしてください！",
"53":"ヒント",
"54":"10 USDT以上が必要",
"55":"掘削を中止する",
"56":"掘削開始成功",
"57":"トップページ",
"58":"両替",
"59":"サービス",
"60":"個人",
"61":"リンクウォレット",
"62":"総計",
"63":"財布",
"64":"換算スケール",
"65":"換算可能",
"66":"交換数量",
"67":"USDTに両替",
"68":"換算レコード",
"69":"換算スケール",
"70":"換算可能",
"71":"交換数量",
"72":"に換算",
"73":"換算レコード",
"74":"FAQ",
"75":"見出し1",
"76":"コンテンツ",
"77":"タイトル2",
"78":"コンテンツ",
"79":"タイトル3",
"80":"コンテンツ",
"81":"両替成功",
"82":"両替成功",
"83":"initWeb 3 Accountメソッド",
"84":"MetaMaskをインストールしてください！",
"85":"100 USDT以上が必要",
"86":"リンクウォレット",
"87":"EOS次世代モバイルノード掘削",
"88":"通貨の換算は必要ありません",
"89":"収入が安定している",
"90":"無料終了",
"91":"CoinbaseはCircleとCoinbaseが共同で構築した技術プロジェクトCENTERがサポートする安定通貨USDCを発展させるための世界最大の統合DeFiプラットフォームの構築に力を入れている",
"92":"このプロジェクトを開始するのは,目まぐるしく変化する暗号化通貨市場において安定性を創造し,同時に暗号化コミュニティの設立初期をめぐる論争で失われた信頼を回復するためである",
"93":"文字",
"94":"等級",
"95":"収益",
"96":"initWeb 3 Accountメソッド",
"97":"MetaMaskをインストールしてください！",
"98":"100 USDT以上が必要",
"99":"リンクウォレット",
"100":"財布",
"101":"引き出し数量",
"102":"USDTを引き出す",
"103":"現金化記録",
"104":"FAQ",
"105":"タイトル1",
"106":"コンテンツ",
"107":"タイトル2",
"108":"コンテンツ",
"109":"タイトル3",
"110":"コンテンツ",
"111":"引き出し成功",
"112":"initWeb 3 Accountメソッド",
"113":"MetaMaskをインストールしてください！",
"114":"100 USDT以上が必要",
"115":"引き出し成功",
"124": "状態",
"125": "数量",
"116": "はじめに",
"117": "十分なバランスを維持するために検証中",
"118": "はじめに",
"119": "十分なバランスを維持するために検証中",
"120": "資金不足",
"121": "成功したアプリケーション",
"126": "最低出金額",
"141": "宝くじ",
"142": "サインイン",
"143": "日",
"144": "サインイン",
"145": "日",
"146":"1日1回ログインでターンテーブル大抽選会、7日間連続ログインで不思議なプレゼント",
"147": "サインインに成功すると、スピンを引くチャンスが得られます",
"148": "今日は終わりです",
"149": "確かに",
"150":"カルーセルドロー"
}
