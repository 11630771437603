export const lang = {
    "1": "Liên kết Wallet",
    "2": "Khai thác",
    "3": "Khai thác ...",
    "4": "Địa chỉ:",
    "5": "Ví không được kết nối",
    "6": "Nhóm doanh thu",
    "7": "Lợi nhuận khai thác",
    "8": "Giá",
    "9": "Số dư trên Wallet",
    "10": "Băng thông (USDT) khi đổi thu nhập khai thác phải giống như khi tham gia khai thác. Nếu băng thông không cho thấy bạn đang đổi, bạn có thể làm mới và thử lại",
    "11": "Lãi suất khai thác hàng năm",
    "12": "APY trong quy trình canh tác năng suất là gì? Lợi tức ước tính trong quy trình canh tác năng suất được tính dựa trên Phần trăm lợi nhuận hàng năm (APY). Đó là tỷ lệ lợi nhuận mà người dùng kiếm được trong một năm",
    "13": "Airdrop",
    "14": "Airdrop",
    "15": "Yêu cầu tiền",
    "16": "Dấu chấm",
    "17": "Ngày",
    "18": "Phần thưởng",
    "19": "Thời gian",
    "20": "Tham gia",
    "21": "Cầm cố",
    "22": "Cầm cố",
    "23": "Yêu cầu tiền",
    "24": "Dấu chấm",
    "25" :"ngày",
    "26": "Phần thưởng",
    "27": "Thời gian",
    "28": "Tham gia",
    "29": "Nút thanh khoản",
    "30": "Nhóm thanh khoản",
    "31": "Thu nhập",
    "32": "Tỷ lệ chiến thắng trước khi bán hàng",
    "33": "Cầm cố",
    "34": "Câu hỏi thường gặp",
    "35": "Tham gia",
    "36": "Airdrop",
    "37": "Số tiền khóa tối thiểu",
    "38": "Hạn chót",
    "39": "Ngày",
    "40": "Phần thưởng",
    "41": "Thời gian",
    "42": "Giới thiệu",
    "43": "Tham gia",
    "44": "Bán trước",
    "45": "Số tiền Khóa Tối thiểu",
    "46": "Hạn chót",
    "47": "Ngày",
    "48": "Phần thưởng",
    "49": "Thời gian",
    "50": "Giới thiệu",
    "51": "phương thức initWeb3Account",
    "52": "Vui lòng cài đặt MetaMask!",
    "53": "Gợi ý",
    "54": "Yêu cầu nhiều hơn 10USDT",
    "55": "Dừng khai thác",
    "56": "Khai thác đã bắt đầu thành công",
    "57": "Trang chủ",
    "58": "Trao đổi",
    "59": "Dịch vụ",
    "60": "Cá nhân",
    "61": "Ví liên kết",
    "62": "Tổng",
    "63": "Ví",
    "64": "Tỷ lệ hối đoái",
    "65": "Có thể trao đổi",
    "66": "Số tiền hoán đổi",
    "67": "Đổi USDT",
    "68": "Bản ghi trao đổi",
    "69": "Tỷ lệ hối đoái",
    "70": "Có thể hoán đổi",
    "71": "Số tiền hoán đổi",
    "72": "Exchange",
    "73": "Bản ghi trao đổi",
    "74": "Câu hỏi thường gặp",
    "75": "Tiêu đề 1",
    "76": "Nội dung",
    "77": "Tiêu đề 2",
    "78": "Nội dung",
    "79": "Tiêu đề 3",
    "80": "Nội dung",
    "81": "Trao đổi thành công",
    "82": "Trao đổi thành công",
    "83": "phương thức initWeb3Account",
    "84": "Vui lòng cài đặt MetaMask!",
    "85": "Yêu cầu nhiều hơn 100USDT",
    "86": "Ví liên kết",
    "87": "Khai thác nút di động thế hệ mới EOS",
    "88": "Không cần chuyển tiền",
    "89": "Thu nhập ổn định",
    "90": "Thoát tự do",
    "91": "Coinbase cam kết xây dựng nền tảng DeFi tích hợp lớn nhất thế giới để phát triển đồng tiền ổn định USDC được hỗ trợ bởi CENTER, một dự án công nghệ do Circle và Coinbase cùng thành lập",
    "92": "Dự án được khởi động để tạo ra sự ổn định trong thị trường tiền điện tử luôn thay đổi, đồng thời khôi phục niềm tin đã mất trong những tranh cãi của cộng đồng tiền điện tử xung quanh những ngày đầu của nó.",
    "93": "Văn bản",
    "94": "Cấp độ",
    "95": "Doanh thu",
    "96": "phương thức initWeb3Account",
    "97": "Vui lòng cài đặt MetaMask!",
    "98": "Yêu cầu nhiều hơn 100USDT",
    "99": "Ví liên kết",
    "100": "Ví",
    "101": "Số tiền rút",
    "102": "Rút USDT",
    "103": "Hồ sơ rút tiền",
    "104": "Câu hỏi thường gặp",
    "105": "Tiêu đề 1",
    "106": "Nội dung",
    "107": "Tiêu đề 2",
    "108": "Nội dung",
    "109": "Tiêu đề 3",
    "110": "Nội dung",
    "111": "Rút tiền thành công",
    "112": "phương thức initWeb3Account",
    "113": "Vui lòng cài đặt MetaMask!",
    "114": "Yêu cầu nhiều hơn 100USDT",
    "115": "Rút tiền thành công",
    "124": "trạng thái",
    "125": "số lượng",
    "116": "Giới thiệu",
    "117": "Xác minh để duy trì đủ số dư",
    "118": "Giới thiệu",
    "119": "Xác minh để duy trì đủ số dư",
    "120": "Không đủ tiền",
    "121": "Ứng dụng thành công",
    "126": "Rút tiền tối thiểu",

    "127": "Thu nhập của tôi",
    "128": "Xem tất cả",
    "129": "Tất cả",
    "130": "Hôm nay",

    "131": "Hồ sơ Rút tiền",
    "132": "Đang xem xét",
    "133": "Đạt",
    "134": "Bị từ chối",

    "135": "Chi tiết Tài khoản",
    "136": "trao đổi USDT",
    "137": "Bản phát hành hàng ngày",
    "138": "Trao đổi TOKEN",
    "139": "Đã thêm hệ thống",
    "140": "Đã thêm hệ thống",

    "141": "Xổ số",
    "142": "Đăng ký",
    "143": "ngày",
    "144": "SING IN",
    "145": "NGÀY",
    "146": "Đăng nhập mỗi ngày một lần để quay xổ số quay lớn và đăng nhập trong 7 ngày liên tiếp để gửi một món quà bí ẩn",
    "147": "Đăng nhập thành công và có cơ hội rút thăm",
    "148": "hôm nay đã kết thúc",
    "149": "Chắc chắn rồi",
    "150":"vẽ băng chuyền"
}
