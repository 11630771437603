import Vue from 'vue'
import Vuex from 'vuex'
import daou from "@/store/modules/daou";
Vue.use(Vuex)

export default new Vuex.Store({
    state: {},
    getters: {},
    mutations: {},
    actions: {},
    modules: {
        daou
    }
})
