var render = function render(){var _vm=this,_c=_vm._self._c;return (
    _vm.$route.name == 'CXC' ||
      _vm.$route.name == 'exchange' ||
      _vm.$route.name == 'personal'||
      _vm.$route.name == 'serve'||
      _vm.$route.name == 'lottery'
      )?_c('div',[_c('van-tabbar',{attrs:{"inactive-color":"#ffffff","active-color":"#ffffff","fixed":"false"},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},_vm._l((_vm.tabbarList),function(item,index){return _c('van-tabbar-item',{key:index,attrs:{"replace":"","to":item.path},scopedSlots:_vm._u([{key:"icon",fn:function(props){return _c('img',{attrs:{"src":props.active ? item.active : item.normal}})}}],null,true)},[_c('span',[_vm._v(_vm._s(item.title))])])}),1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }