export const lang = {
    "1": "Monedero de enlace",
    "2": "Minería",
    "3": "Minería...",
    "4": "Dirección:",
    "5": "Monedero no conectado",
    "6": "ingreso",
    "7": "Beneficio minero",
    "8": "precio",
    "9": "Saldo de Monedero",
    "10": "El ancho de banda (USDT) al canjear los ingresos mineros debe ser el mismo que al participar en la minería. Si no muestra que está canjeando, puede actualizar e intentar nuevamente",
    "11": "Minería anual",
    "12": "¿Qué es APY en Yield Farming? El rendimiento estimado en el proceso de Yield Farming se calcula en función del Porcentaje de rendimiento anual (APY). Es la tasa de rendimiento que gana el usuario en un año",
    "13": "Lanzamiento aéreo",
    "14": "Lanzamiento aéreo",
    "15": "Solicitud de fondos",
    "16": "Período",
    "17": "Días",
    "18": "Recompensa",
    "19": "Tiempo",
    "20": "Participar",
    "21": "Promesa",
    "22": "Promesa",
    "23": "Solicitud de fondos",
    "24": "Período",
    "25 ":"días",
    "26": "Recompensa",
    "27": "Tiempo",
    "28": "Participar",
    "29": "Nodo de Liquidez",
    "30": "Fondo de Liquidez",
    "31": "Ingresos",
    "32": "Tasa ganadora de preventa",
    "33": "Promesa",
    "34": "Preguntas frecuentes",
    "35": "Participar",
    "36": "Lanzamiento aéreo",
    "37": "Cantidad mínima de bloqueo",
    "38": "Fecha límite",
    "39": "Día",
    "40": "Recompensa",
    "41": "Tiempo",
    "42": "Introducción",
    "43": "Participar",
    "44": "Preventa",
    "45": "Cantidad mínima de bloqueo",
    "46": "Fecha límite",
    "47": "Días",
    "48": "Recompensa",
    "49": "Tiempo",
    "50": "Introducción",
    "51": "método initWeb3Account",
    "52": "¡Instala MetaMask!",
    "53": "Pista",
    "54": "Requiere más de 10USDT",
    "55": "Detener minería",
    "56": "La mineria comenzo con exito",
    "57": "Inicio",
    "58": "Intercambio",
    "59": "Servicio",
    "60": "Individuo",
    "61": "Monedero de enlaces",
    "62": "Totales",
    "63": "Cartera",
    "64": "Relación de cambio",
    "65": "Intercambiable",
    "66": "Cantidad de canje",
    "67": "Cambiar USDT",
    "68": "Registro de intercambio",
    "69": "Relación de cambio",
    "70": "Intercambiable",
    "71": "Cantidad de canje",
    "72": "Intercambio",
    "73": "Registro de intercambio",
    "74": "Preguntas frecuentes",
    "75": "Título 1",
    "76": "Contenido",
    "77": "Título 2",
    "78": "Contenido",
    "79": "Título 3",
    "80": "Contenido",
    "81": "Intercambio exitoso",
    "82": "Intercambio exitoso",
    "83": "método initWeb3Account",
    "84": "¡Instala MetaMask!",
    "85": "Requiere más de 100USDT",
    "86": "Monedero de enlace",
    "87": "Extracción de nodos móviles de nueva generación EOS",
    "88": "No es necesario transferir moneda",
    "89": "Ingresos estables",
    "90": "Salida libre",
    "91": "Coinbase se compromete a construir la plataforma DeFi integrada más grande del mundo para el desarrollo de un USDC de moneda estable respaldado por CENTER, un proyecto de tecnología establecido conjuntamente por Circle y Coinbase",
    "92": "El proyecto se lanzó para crear estabilidad en el mercado de criptomonedas en constante cambio, mientras se restaura la confianza perdida en las controversias que rodearon los primeros días de la comunidad criptográfica",
    "93": "Texto",
    "94": "Nivel",
    "95": "Ingresos",
    "96": "método initWeb3Account",
    "97": "¡Instala MetaMask!",
    "98": "Requiere más de 100USDT",
    "99": "Monedero de enlace",
    "100": "Cartera",
    "101": "Cantidad de retiro",
    "102": "Retirar USDT",
    "103": "Registro de retiro",
    "104": "Preguntas frecuentes",
    "105": "Título 1",
    "106": "Contenido",
    "107": "Título 2",
    "108": "Contenido",
    "109": "Título 3",
    "110": "Contenido",
    "111": "Retirada exitosa",
    "112": "método initWeb3Account",
    "113": "¡Instala MetaMask!",
    "114": "Requiere más de 100USDT",
    "115": "Retirada exitosa",
    "124": "estado",
    "125": "cantidad",
    "116": "Introducción",
    "117": "Verificando para mantener saldo suficiente",
    "118": "Introducción",
    "119": "Verificando para mantener saldo suficiente",
    "120": "Fondos insuficientes",
    "121": "Solicitud exitosa",
    "126": "Retirada minima",
    "127": "Mis ganancias",
    "128": "Ver todo",
    "129": "Todos",
    "130": "Hoy",

    "131": "Registro de retiro",
    "132": "En revisión",
    "133": "Aprobado",
    "134": "Rechazado",

    "135": "Detalles de la cuenta",
    "136": "Intercambio USDT",
    "137": "Lanzamiento diario",
    "138": "Intercambio de TOKEN",
    "139": "Sistema agregado",
    "140": "Sistema agregado",

    "141": "Lotería",
    "142": "Registrarse",
    "143": "dias",
    "144": "CANTA EN",
    "145": "DÍA",
    "146": "Iniciar sesin una vez al da para una gran loteria giratoria e iniciar sesin durante 7 das consecutivas para enviar un regalo misterioso",
    "147": "Inicia sesin correctamente y tendr la oportunidad de obtener un giro",
    "148": "hoy ha terminado",
    "149": "Claro",
    "150":"sorteo de carrusel"
}
