export const lang = {
    "1": "鏈接錢包",
    "2": "挖礦",
    "3": "挖礦中...",
    "4": "地址:",
    "5": "未連接錢包",
    "6": "收益池",
    "7": "挖礦收益",
    "8": "價格",
    "9": "錢包餘額",
    "10": "兌換採礦收入時的帶寬（USDT）必須與參與採礦時的帶寬一致。如果沒有顯示你正在兌換，你可以刷新並再次嘗試",
    "11": "挖礦年利率",
    "12": "什麼是單產農業中的 APY？ 單產農業過程中的估計回報是根據年產量百分比 (APY) 計算的。 它是用戶在一年內獲得的回報率",
    "13": "空投",
    "14": "空投",
    "15": "要求資金",
    "16": "週期",
    "17": "天",
    "18": "獎勵",
    "19": "時間",
    "20": "參與",
    "21": "質押",
    "22": "質押",
    "23": "要求資金",
    "24": "週期",
    "25": "天",
    "26": "獎勵",
    "27": "時間",
    "28": "參與",
    "29": "流動資金節點",
    "30": "流動資金池",
    "31": "收入",
    "32": "預售中獎率",
    "33": "質押",
    "34": "常見問題解答",
    "35": "參與",
    "36": "空投",
    "37": "最小鎖定金額",
    "38": "期限",
    "39": "天",
    "40": "獎勵",
    "41": "時間",
    "42": "介紹",
    "43": "參與",
    "44": "預售",
    "45": "最小鎖定金額",
    "46": "期限",
    "47": "天",
    "48": "獎勵",
    "49": "時間",
    "50": "介紹",
    "51": "initWeb3Account 方法",
    "52": "請安裝MetaMask!",
    "53": "提示",
    "54": "需要大於10USDT",
    "55": "停止挖礦",
    "56": "挖礦啟動成功",
    "57": "首頁",
    "58": "兌換",
    "59": "服務",
    "60": "個人",
    "61": "鏈接錢包",
    "62": "總計",
    "63": "錢包",
    "64": "兌換比例",
    "65": "可兌換",
    "66": "交換數量",
    "67": "兌換USDT",
    "68": "兌換記錄",
    "69": "兌換比例",
    "70": "可兌換",
    "71": "交換數量",
    "72": "兌換",
    "73": "兌換記錄",
    "74": "常見問題解答",
    "75": "標題1",
    "76": "內容",
    "77": "標題2",
    "78": "內容",
    "79": "標題3",
    "80": "內容",
    "81": "兌換成功",
    "82": "兌換成功",
    "83": "initWeb3Account 方法",
    "84": "請安裝MetaMask!",
    "85": "需要大於100USDT",
    "86": "鏈接錢包",
    "87": "EOS 新一代移動節點挖礦",
    "88": "無需轉移貨幣",
    "89": "收入穩定",
    "90": "免費退出",
    "91": "Coinbase致力於打造全球最大的整合DeFi平台用於發展由Circle和Coinbase合夥建立的技術項目CENTER支持的穩定幣USDC",
    "92": "啟動該項目是為了在瞬息萬變的加密貨幣市場中創造穩定性,同時恢復在加密社區圍繞成立初期的爭議中失去的信任.",
    "93": "文字",
    "94": "等級",
    "95": "收益",
    "96": "initWeb3Account 方法",
    "97": "請安裝MetaMask!",
    "98": "需要大於100USDT",
    "99": "鏈接錢包",
    "100": "錢包",
    "101": "提現數量",
    "102": "提現USDT",
    "103": "提現記錄",
    "104": "常見問題解答",
    "105": "標題1",
    "106": "內容",
    "107": "標題2",
    "108": "內容",
    "109": "標題3",
    "110": "內容",
    "111": "提現成功",
    "112": "initWeb3Account 方法",
    "113": "請安裝MetaMask!",
    "114": "需要大於100USDT",
    "115": "提現成功",
    "116": "介紹",
    "117": "正在核實保持餘額充足",
    "118": "介紹",
    "119": "正在核實保持餘額充足",
    "120": "資金不足",
    "121": "申請成功",
    "122": "資金不足",
    "123": "申請成功",
    "124": "狀態",
    "125": "數量",
    "126": "最低提現",

    "127": "我的收益",
    "128": "查看所有",
    "129": "全部收益",
    "130": "今天收益",

    "131": "賬戶提現記錄",
    "132": "審核中",
    "133": "已通過",
    "134": "已拒絕",

    "135": "賬戶明細",
    "136": "USDT兌換",
    "137": "每日釋放",
    "138": "TOKEN兌換",
    "139": "系統添加",
    "140": "系統添加",

    "141": "簽到",
    "142": "簽到",
    "143": "天",
    "144": "簽到",
    "145": "天",
    "146": "每日簽到一次大轉盤抽獎，連續簽到7天送神秘禮物",
    "147": "签到成功，获得一次转盘抽奖机会",
    "148": "今天結束了",
    "149":"當然",
    "150":"轉盤抽獎",
    "151":"取消"

}
