
const daouStore = {
    state: {
        web3Modal: null,
        chainId: "TRC",//0以太 1币安 2波场
        usdtaddress:"TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t",
        decimal:6
    },
    mutations: {
        setWeb3Modal(state, web3Modal) {
            state.web3Modal = web3Modal
            console.log("state",state.web3Modal)
        },
        saveChainId(state,chainId){
            state.chainId = chainId;
            if(chainId == "ERC"){
                state.decimal = 6
                state.usdtaddress = "0xdAC17F958D2ee523a2206206994597C13D831ec7"
            }else if (chainId == "BSC"){
                state.decimal = 18
                state.usdtaddress = "0x55d398326f99059fF775485246999027B3197955"
            }else if (chainId == "TRC"){
                state.decimal = 6
                state.usdtaddress = "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t"
            }
        },
    },
    actions: {
        async connect({ commit},library) {
            console.log("library",library)
            commit('setWeb3Modal', library)
        },

    }
}

export default daouStore;
