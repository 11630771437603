<template>
  <div
      v-if="
      $route.name == 'CXC' ||
        $route.name == 'exchange' ||
        $route.name == 'personal'||
        $route.name == 'serve'||
        $route.name == 'lottery'
        "
  >
    <van-tabbar v-model="active" inactive-color="#ffffff" active-color="#ffffff"  fixed="false">
      <van-tabbar-item replace v-for="(item, index) in tabbarList" :key="index" :to="item.path">
        <span>{{ item.title }}</span>
        <img slot="icon" slot-scope="props" :src="props.active ? item.active : item.normal" />
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import lw1 from "@/assets/image/lw1.png";
import lw from "@/assets/image/lw.png";
import wd from "@/assets/image/wd.png";
import wd1 from "@/assets/image/wd1.png";
import sy from "@/assets/image/sy.png";
import sy1 from "@/assets/image/sy1.png";
import dh from "@/assets/image/dh.png";
import dh1 from "@/assets/image/dh1.png";
import fw from "@/assets/image/fw.png";
import fw1 from "@/assets/image/fw1.png";
export default {
  name: "tabbar",
  inject: ['reload'],
  data() {
    return {
      active: 0,
      tabbarList: [
        {
          path: "/",
          title: this.$t('lang.57'),
          normal:
          sy,
          active:
          sy1,
        },
        {
          path: "/exchange",
          title: this.$t('lang.58'),
          normal:
          dh,
          active:
          dh1,
        },
        {
          path: "/lottery",
          title: this.$t('lang.141'),
          normal:
          lw,
          active:
          lw1,
        },
        {
          path: "/serve",
          title: this.$t('lang.59'),
          normal:
          fw,
          active:
          fw1,
        },
        {
          path: "/personal",
          title: this.$t('lang.60'),
          normal:
          wd,
          active:
          wd1,
        },

      ],
    };
  },

  //监听路由变化
  watch: {
    // '$i18n.locale'(newValue, oldValue) {
    //   this.reload();
    //   console.log('language switch from ' + oldValue + ' to ' + newValue + '')
    // },
      $route(to) {
      this.activeTab(to.path);
    },
  },
  //初始化
  async created(){
    if (this.$route.name == "CXC") {
      this.active = 0;
    } else if (this.$route.name == "exchange") {
      this.active = 1;
    } else if (this.$route.name == "serve") {
      this.active = 2;
    }else if (this.$route.name == "personal") {
      this.active = 3;
    }else if (this.$route.name == "lottery") {
      this.active = 4;
    }

  },
  methods: {
    activeTab(path) {
      var index = this.tabbarList.map((item) => item.path).indexOf(path);
      if (index != -1) {
        this.active = index;
      }
    },
  },
};
</script>
<style>
.van-tabbar {
  height: 70px;
  background-color: #1352ff;
}
.van-tabbar-item--active {
  background-color: #1352ff;
}
.van-hairline--top-bottom {
  /* position: relative; */
  margin-top: 1rem;
}
.van-tabbar-item__icon {
  margin-bottom: 15px;
}
</style>
